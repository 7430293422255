import { Link } from 'react-router-dom';
import React from 'react';

import GA from '../lib/GoogleAnalytics';
import { useAuth } from '../contexts/AuthContext';
import { useUserProfilePicture } from '../contexts/UserProfilePictureContext';

import '../scss/page/_profile-nav-item.scss';

function ProfileNavItem() {
  const auth = useAuth();
  const [pictureUrl] = useUserProfilePicture();

  function logout() {
    GA.trackEvent({ category: 'click', action: 'clickedLogOutButton' });
    auth.logout();
  }

  return (
    <div className='profile-nav-item'>
      <button className='btn btn-link text-white me-3 p-0' id='logoutButton' onClick={ logout }>
        Log Out
      </button>
      <Link to='/profile'>
        <img
          alt='Profile picture'
          className='profile-picture'
          src={ pictureUrl }
        />
      </Link>
    </div>
  );
}

export default ProfileNavItem;
